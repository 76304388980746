<template>
  <div class="headerbox">
  <div class="header">
    <div class="headerL">
      <a onclick="javascript:history.back(-1)" class="goback"><img src="images/goback.png" /></a>
    </div>
    <div class="headerC">
      <p>购物车(5)</p>
    </div>
    <div class="headerR"></div>
  </div>
</div>
<div class="clear"></div>
<div class="hbox"></div>
<div class="kbox"></div>
<div class="gwcbox">
  <div class="gwcbox_1">
    <div class="gwc1_1">
      <div class="g1">
        <div class="gwccheck on"></div>
      </div>
      <div class="g2">
        <a href="dpxq.html">
        <span>皇宫婚纱旗舰店</span>
        <img src="images/mre1.png" />
        </a>
      </div>
      <div class="g3">
        <img src="images/quanicon.png" />
      </div>
    </div>
    <div class="clear"></div>
    <div class="gwc1_2">
      <div class="gwcone">
        <div class="go1">
          <div class="gwccheck on"></div>
        </div>
        <div class="go2"><a href="xq.html"><img src="images/gwc1.png" /></a></div>
        <div class="go3">
          <div class="go3_1">
            <a href="xq.html"><p class="p1">2015新款婚纱冬季新娘结婚韩版蕾...</p></a>
            <p class="p2">￥895</p>
          </div>
          <div class="go3_2">
            <p class="p3">颜色：白色；尺码：L</p>
            <p class="p4">￥495.9</p>
          </div>
          <div class="go3_3">
            <div class="num1">-</div>
            <div class="num2">1</div>
            <div class="num3">+</div>
            <div class="del"><img src="images/del.png" /></div>
          </div>
        </div>
      </div>
      <div class="gwcone">
        <div class="go1">
          <div class="gwccheck on"></div>
        </div>
        <div class="go2"><a href="xq.html"><img src="images/gwc2.png" /></a></div>
        <div class="go3">
          <div class="go3_1">
            <a href="xq.html"><p class="p1">2015新款敬酒服冬季新娘结婚韩版...</p></a>
            <p class="p2">￥895</p>
          </div>
          <div class="go3_2">
            <p class="p3">颜色：红色；尺码：L</p>
            <p class="p4">￥298.9</p>
          </div>
          <div class="go3_3">
            <div class="num1">-</div>
            <div class="num2">1</div>
            <div class="num3">+</div>
            <div class="del"><img src="images/del.png" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="kbox"></div>
  <div class="gwcbox_1">
    <div class="gwc1_1">
      <div class="g1">
        <div class="gwccheck"></div>
      </div>
      <div class="g2">
        <a href="dpxq.html">
        <span>小米零食铺</span>
        <img src="images/mre1.png" />
        </a>
      </div>
      <div class="g3">
        <img src="images/quanicon.png" />
      </div>
    </div>
    <div class="clear"></div>
    <div class="gwc1_2">
      <div class="gwcone">
        <div class="go1">
          <div class="gwccheck"></div>
        </div>
        <div class="go2"><a href="xq.html"><img src="images/gwc3.png" /></a></div>
        <div class="go3">
          <div class="go3_1">
            <a href="xq.html"><p class="p1">秋冬款韩版半高领中长款宽松套头打...</p></a>
            <p class="p2">￥15.5</p>
          </div>
          <div class="go3_2">
            <p class="p3">口味：烧烤味</p>
            <p class="p4">￥9.9</p>
          </div>
          <div class="go3_3">
            <div class="num1">-</div>
            <div class="num2">1</div>
            <div class="num3">+</div>
            <div class="del"><img src="images/del.png" /></div>
          </div>
        </div>
      </div>
      <div class="gwcone">
        <div class="go1">
          <div class="gwccheck"></div>
        </div>
        <div class="go2"><a href="xq.html"><img src="images/gwc3.png" /></a></div>
        <div class="go3">
          <div class="go3_1">
            <a href="xq.html"><p class="p1">秋冬款韩版半高领中长款宽松套头打...</p></a>
            <p class="p2">￥45</p>
          </div>
          <div class="go3_2">
            <p class="p3">颜色：白色；尺码：L</p>
            <p class="p4">￥29.8</p>
          </div>
          <div class="go3_3">
            <div class="num1">-</div>
            <div class="num2">1</div>
            <div class="num3">+</div>
            <div class="del"><img src="images/del.png" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="kbox"></div>
  <div class="gwcbox_1">
    <div class="gwc1_1">
      <div class="g1">
        <div class="gwccheck"></div>
      </div>
      <div class="g2">
        <a href="dpxq.html">
        <span>日韩衣橱</span>
        <img src="images/mre1.png" />
        </a>
      </div>
      <div class="g3">
        <img src="images/quanicon.png" />
      </div>
    </div>
    <div class="clear"></div>
    <div class="gwc1_2">
      <div class="gwcone">
        <div class="go1">
          <div class="gwccheck"></div>
        </div>
        <div class="go2"><a href="xq.html"><img src="images/gwc3.png" /></a></div>
        <div class="go3">
          <div class="go3_1">
            <a href="xq.html"><p class="p1">秋冬款韩版半高领中长款宽松套头打...</p></a>
            <p class="p2">￥15.5</p>
          </div>
          <div class="go3_2">
            <p class="p3">颜色：紫色；尺码：均码</p>
            <p class="p4">￥9.9</p>
          </div>
          <div class="go3_3">
            <div class="num1">-</div>
            <div class="num2">1</div>
            <div class="num3">+</div>
            <div class="del"><img src="images/del.png" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="fbox1"></div>
<div class="hejiBox">
  <div class="heji">
    <div class="heji_1">
      <div class="gwccheck on"></div>
    </div>
    <div class="heji_2">全选</div>
    <div class="heji_3"><p>合计：<span>￥784.80</span></p></div>
    <div class="heji_4">为您节省￥605.2</div>
    <div class="heji_5">
      <a href="jiesuan.html">去结算(2)</a>
    </div>
  </div>
</div>
  <footer-nva ac="cart"></footer-nva>
</template>

<script>
import FooterNva from '@/components/FooterNva.vue';
export default {
  components: { FooterNva },
    name:'shopCartPage',
};
</script>

<style>
</style>